<template>
  <div
    class="flex flex-dir--top h100"
    :class="[
      'ptp-setting',
      newFormData.ptpFileFormat == 'mpr' ? 'ptp-setting-full' : '',
    ]"
  >
    <breadcrumb
      :title="$route.query.line || '设备对接'"
      childTitle="PTP"
      @goBackPage="goBackPage"
    >
      <div v-if="!previewInfo.isPreview">
        <a-button
          @click.native="handleOpenChangeRecord"
          id="equipment_bluen_ptp_change_record"
          class="mr20"
        >
          修改记录
        </a-button>
        <a-button
          @click="ptpdataTransmit"
          :disabled="!ableSaveSetting"
          type="primary"
          id="equipment_setting_save_btn"
          ><a-icon type="save" />{{ $t('common.saveSetting') }}</a-button
        >
      </div>
      <div v-if="previewInfo.isApply">
        <a-button
          @click="ptpdataTransmit"
          :disabled="!ableSaveSetting"
          type="primary"
          id="equipment_setting_save_btn"
          ><a-icon type="save" />应用设置</a-button
        >
      </div>
    </breadcrumb>
    <div class="wrap flex-box--1 h0 y-auto">
      <div class="file-format">
        <span class="file-format-title head-title">{{
          $t('PTP.outputFileFormat')
        }}</span>
        <div class="file-format-radio">
          <span class="small-title">{{ $t('PTP.outputFileFormat') }}：</span>
          <a-radio-group v-model="newFormData.ptpFileFormat">
            <a-radio value="default" id="ptp_setting_file_format_radio_default"
              ><span id="ptp_setting_file_format_radio_default_label">
                {{ $t('PTP.outputDefaultFileFormat') }}
              </span></a-radio
            >
            <a-radio value="mpr" id="ptp_setting_file_format_radio_mpr"
              ><span id="ptp_setting_file_format_radio_mpr_label">
                {{ $t('PTP.outputMPRFormat') }}
              </span></a-radio
            >
          </a-radio-group>
        </div>
      </div>
      <div class="file-format-setting file-format">
        <span class="file-format-title head-title">{{
          $t('PTP.fileFormatSettings')
        }}</span>
        <div class="file-format-setting-radio file-format-radio">
          <span class="small-title">{{ $t('PTP.wrapSettings') }}</span>
          <a-radio-group v-model="newFormData.fileFormatSetting.platform">
            <a-radio
              value="windows"
              id="ptp_setting_file_format_setting_radio_windows"
              ><span id="ptp_setting_file_format_radio_windows_label">
                windows
              </span></a-radio
            >
            <a-radio
              value="unix"
              id="ptp_setting_file_format_setting_radio_unix"
              ><span id="ptp_setting_file_format_radio_unix_label">
                unix
              </span></a-radio
            >
          </a-radio-group>
        </div>
        <div class="file-format-radio file-format-setting-radio">
          <span class="small-title">{{ $t('PTP.enCodeSettings') }}</span>
          <a-radio-group v-model="newFormData.fileFormatSetting.fileEncoding">
            <a-radio
              value="utf-8"
              id="ptp_setting_file_format_setting_radio_fileEncoding_UTF-8"
              ><span
                id="ptp_setting_file_format_radio_fileEncoding_UTF-8_label"
              >
                UTF-8
              </span></a-radio
            >
            <a-radio
              value="utf8bom"
              id="ptp_setting_file_format_setting_radio_fileEncoding_bom_UTF-8"
              ><span
                id="ptp_setting_file_format_radio_fileEncoding_bom_UTF-8_label"
              >
                {{ $t('PTP.utf8bom') }}
              </span></a-radio
            >
            <a-radio
              value="gbk"
              id="ptp_setting_file_format_setting_radio_fileEncoding_ansi"
              ><span id="ptp_setting_file_format_radio_fileEncoding_ansi_label">
                ansi
              </span></a-radio
            >
          </a-radio-group>
        </div>
        <div
          v-if="newFormData.ptpFileFormat == 'default'"
          class="file-format-radio file-format-setting-radio"
        >
          <span class="small-title">输出文件后缀：</span>
          <a-input
            class="fileSuffix-input"
            :maxLength="5"
            v-model="newFormData.fileFormatSetting.fileSuffix"
            id="ptp_setting_fileSuffix_input"
            @change="
              handleInputCheck(
                newFormData.fileFormatSetting.fileSuffix,
                'fileSuffix'
              )
            "
          ></a-input>
          <span class="ml15 fileSuffix-span">
            示例：<span
              >下料文件{{ newFormData.fileFormatSetting.fileSuffix }}</span
            >
          </span>
        </div>
      </div>
      <div
        class="default-setting"
        v-if="newFormData.ptpFileFormat == 'default'"
      >
        <div class="bluen-ptp">
          <div class="small-plank">
            <span class="head-title small-plank-title">{{
              $t('PTP.partCoordinate')
            }}</span>
            <div class="small-plank-content">
              <div>
                <span class="small-title">{{ $t('PTP.xPointSymbol') }}</span>
                <a-radio-group v-model="formData.xPointSymbol">
                  <a-radio
                    value="normal"
                    id="ptp_setting_xPointSymbol_normal_radio"
                    ><span id="ptp_setting_xPointSymbol_normal_radio_label">
                      {{ $t('PTP.upRight') }}
                    </span></a-radio
                  >
                  <a-radio
                    value="negative"
                    id="ptp_setting_xPointSymbol_negative_radio"
                    ><span id="ptp_setting_xPointSymbol_negative_radio_label">
                      {{ $t('PTP.negative') }}
                    </span></a-radio
                  >
                </a-radio-group>
              </div>
              <div>
                <span class="small-title">{{ $t('PTP.yPointSymbol') }}</span>
                <a-radio-group v-model="formData.yPointSymbol">
                  <a-radio
                    value="normal"
                    id="ptp_setting_yPointSymbol_normal_radio"
                    ><span id="ptp_setting_yPointSymbol_normal_radio_label">
                      {{ $t('PTP.upRight') }}
                    </span></a-radio
                  >
                  <a-radio
                    value="negative"
                    id="ptp_setting_yPointSymbol_negative_radio"
                    ><span id="ptp_setting_yPointSymbol_negative_radio_label">
                      {{ $t('PTP.negative') }}
                    </span></a-radio
                  >
                </a-radio-group>
              </div>
              <div>
                <span class="small-title">{{ $t('PTP.zPointSymbol') }}</span>
                <a-radio-group v-model="formData.zPointSymbol">
                  <a-radio
                    value="normal"
                    id="ptp_setting_zPointSymbol_normal_radio"
                    ><span id="ptp_setting_zPointSymbol_normal_radio_label">
                      {{ $t('PTP.upRight') }}
                    </span></a-radio
                  >
                  <a-radio
                    value="negative"
                    id="ptp_setting_zPointSymbol_negative_radio"
                    ><span id="ptp_setting_zPointSymbol_negative_radio_label">
                      {{ $t('PTP.negative') }}
                    </span></a-radio
                  >
                </a-radio-group>
              </div>
            </div>
            <div class="file-format">
              <span class="file-format-title head-title">台面设置</span>
              <div class="file-format-radio">
                <span class="small-title">板件镜像设置：</span>
                <a-checkbox
                  v-model="newFormData.xDatumSwap"
                  id="ptp_setting_xDatumSwap_checkbox"
                  ><span id="ptp_setting_xDatumSwap_checkbox_label"
                    >X轴镜像</span
                  ></a-checkbox
                >
                <a-checkbox
                  v-model="newFormData.yDatumSwap"
                  id="ptp_setting_yDatumSwap_checkbox"
                  ><span id="ptp_setting_yDatumSwap_checkbox_label"
                    >Y轴镜像</span
                  ></a-checkbox
                >
                （板件镜像时使用此功能）
              </div>
              <div class="file-format-radio">
                <span class="small-title">xy轴互换设置：</span>
                <a-checkbox
                  v-model="newFormData.xyReverse"
                  id="ptp_setting_xyReverse_radiocheckbox"
                ></a-checkbox>
                <span class="ml8">xy轴互换</span>
              </div>
            </div>
          </div>
          <div class="knife-table">
            <span class="knife-table-title head-title">{{
              $t('common.knivesStore')
            }}</span>
            <div class="table-box">
              <el-table
                :data="formData.knives"
                :row-style="changeStyle"
                :cell-style="columnStyle"
                :header-cell-style="headerStyle"
                :header-row-style="headerRowStyle"
                @row-click="choseKnife"
                :row-class-name="tableRowClassName"
                :cell-class-name="tableCellClassName"
                @cell-click="cellClick"
              >
                <el-table-column
                  v-for="(knife, indexs) in knivesColumns"
                  :key="indexs + '1'"
                  :label="`${translateLang(knife.label)}${knife.unit ?? ''}`"
                  :prop="knife.props"
                >
                  <template slot-scope="scope">
                    <div
                      v-if="
                        scope.row.index === rowIndex &&
                        scope.column.index === columnIndex &&
                        knife.props === 'type'
                      "
                      class="inputnum"
                    >
                      <el-select
                        v-model="scope.row.type"
                        @change="modifyKnife"
                        :placeholder="translateLang('common.select')"
                        :id="`ptp_setting_knifeTable_${scope.row.index}_${knife.props}_select`"
                      >
                        <el-option
                          v-for="(item, index) in knives"
                          :key="index"
                          :value="item.value"
                          :id="`ptp_setting_knifeTable_${scope.row.index}_${knife.props}_select_option_${index}`"
                          >{{ translateLang(item.name)
                          }}{{ item.unit ?? '' }}</el-option
                        >
                      </el-select>
                    </div>
                    <div
                      v-else-if="
                        scope.row.index === rowIndex &&
                        scope.column.index === columnIndex &&
                        knife.props !== 'type'
                      "
                    >
                      <el-input
                        v-model="scope.row[knife.props]"
                        ref="editInput"
                        type="string"
                        size="mini"
                        @blur="inputBlur(scope.row)"
                        @change="inputBlur(scope.row)"
                        :id="`ptp_setting_knifeTable_${scope.row.index}_${knife.props}_input`"
                        @input="handleInputCheck(scope.row, knife.props)"
                      />
                    </div>
                    <div
                      class="knives_type"
                      v-else
                      :id="`ptp_setting_knifeTable_${scope.row.index}_${knife.props}`"
                    >
                      {{ scope.row[knife.props] }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="knife-operation">
              <a-button
                class="button_insert mr12"
                @click.native="addNewKnife"
                id="ptp_setting_knifeTable_addNewKnife_btn"
                >{{ $t('PTP.knifeTable.insertRow') }}</a-button
              >
              <a-button
                class="button_delete"
                @click.native="deleteKnife"
                id="ptp_setting_knifeTable_deleteNewKnife_btn"
                >{{ $t('PTP.knifeTable.deleteRow') }}</a-button
              >
            </div>
          </div>
        </div>
        <div class="code-box">
          <div class="tag-lake">
            <div class="tag-lake-title">
              <span>{{ $t('PTP.labelPool') }}</span>
              <span>{{ $t('PTP.dragTip') }}</span>
            </div>
            <div class="tag-list">
              <span
                v-for="(item, index) in tagList"
                :key="index"
                @dragstart="startDrag($event, item)"
                draggable="true"
                :class="`tag-item${index}`"
                @click="copyTag(index)"
                data-clipboard-action="copy"
                :data-clipboard-text="'{' + item + '}'"
                :id="`ptp_setting_taglist_tag${index}`"
              >
                {{ item }}
              </span>
            </div>
          </div>
          <div class="code-content">
            <div>
              <span class="head-title code-content-title">{{
                $t('PTP.procedure')
              }}</span>
              <div class="text-area">
                <div>
                  <span>{{ $t('PTP.beginCode') }}</span>
                  <a-textarea
                    v-model="formData.beginCode"
                    @drop="handleDragOver($event, 'beginCode')"
                    id="ptp_setting_beginCode_textarea"
                  ></a-textarea>
                </div>
                <div>
                  <span>{{ $t('PTP.endCode') }}</span>
                  <a-textarea
                    v-model="formData.endCode"
                    @drop="handleDragOver($event, 'endCode')"
                    id="ptp_setting_endCode_textarea"
                  ></a-textarea>
                </div>
              </div>
            </div>
            <div>
              <span class="head-title code-content-title">{{
                $t('PTP.spindleCutter')
              }}</span>
              <div class="text-area">
                <div>
                  <span>{{ $t('PTP.zzdKnifeBeginCode') }}</span>
                  <a-textarea
                    v-model="formData.zzdKnifeBeginCode"
                    @drop="handleDragOver($event, 'zzdKnifeBeginCode')"
                    id="ptp_setting_zzdKnifeBeginCode_textarea"
                  ></a-textarea>
                </div>
                <div>
                  <span>{{ $t('PTP.zzdKnifeEndCode') }}</span>
                  <a-textarea
                    v-model="formData.zzdKnifeEndCode"
                    @drop="handleDragOver($event, 'zzdKnifeEndCode')"
                    id="ptp_setting_zzdKnifeEndCode_textarea"
                  ></a-textarea>
                </div>
              </div>
            </div>
            <div>
              <span class="head-title code-content-title">{{
                $t('PTP.verticalDrill')
              }}</span>
              <div class="text-area">
                <div>
                  <div>
                    <span>{{ $t('PTP.czzBeginCode') }}</span>
                    <a-textarea
                      v-model="formData.czzBeginCode"
                      @drop="handleDragOver($event, 'czzBeginCode')"
                      id="ptp_setting_czzBeginCode_textarea"
                    ></a-textarea>
                  </div>
                  <div>
                    <span>{{ $t('PTP.czzEndCode') }}</span>
                    <a-textarea
                      v-model="formData.czzEndCode"
                      @drop="handleDragOver($event, 'czzEndCode')"
                      id="ptp_setting_czzEndCode_textarea"
                    ></a-textarea>
                  </div>
                </div>
                <div>
                  <div>
                    <span>{{ $t('PTP.czzKnifeBeginCode') }}</span>
                    <a-textarea
                      v-model="formData.czzKnifeBeginCode"
                      @drop="handleDragOver($event, 'czzKnifeBeginCode')"
                      id="ptp_setting_czzKnifeBeginCode_textarea"
                    ></a-textarea>
                  </div>
                  <div>
                    <span>{{ $t('PTP.czzKnifeEndCode') }}</span>
                    <a-textarea
                      v-model="formData.czzKnifeEndCode"
                      @drop="handleDragOver($event, 'czzKnifeEndCode')"
                      id="ptp_setting_czzKnifeEndCode_textarea"
                    ></a-textarea>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span class="head-title code-content-title">{{
                $t('PTP.sidetrack')
              }}</span>
              <div class="text-area">
                <div>
                  <div>
                    <span>{{ $t('PTP.czBeginCode') }}</span>
                    <a-textarea
                      v-model="formData.czBeginCode"
                      @drop="handleDragOver($event, 'czBeginCode')"
                      id="ptp_setting_czBeginCode_textarea"
                    ></a-textarea>
                  </div>
                  <div>
                    <span>{{ $t('PTP.czEndCode') }}</span>
                    <a-textarea
                      v-model="formData.czEndCode"
                      @drop="handleDragOver($event, 'czEndCode')"
                      id="ptp_setting_czEndCode_textarea"
                    ></a-textarea>
                  </div>
                </div>
                <div>
                  <div>
                    <span>{{ $t('PTP.czKnifeBeginCode') }}</span>
                    <a-textarea
                      v-model="formData.czKnifeBeginCode"
                      @drop="handleDragOver($event, 'czKnifeBeginCode')"
                      id="ptp_setting_czKnifeBeginCode_textarea"
                    ></a-textarea>
                  </div>
                  <div>
                    <span>{{ $t('PTP.czKnifeEndCode') }}</span>
                    <a-textarea
                      v-model="formData.czKnifeEndCode"
                      @drop="handleDragOver($event, 'czKnifeEndCode')"
                      id="ptp_setting_czKnifeEndCode_textarea"
                    ></a-textarea>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span class="head-title code-content-title">{{
                $t('common.pause')
              }}</span>
              <div class="text-area">
                <div>
                  <span>{{ $t('PTP.pauseCode') }}</span>
                  <a-textarea
                    v-model="formData.pauseCode"
                    @drop="handleDragOver($event, 'pauseCode')"
                    id="ptp_setting_pauseCode_textarea"
                  ></a-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="safe-height">
          <span class="head-title">{{ $t('PTP.zSafeHeight') }}</span>
          <div>
            <span class="small-title">{{ $t('PTP.spindleKnife') }}：</span>
            <a-input
              v-model="newFormData.main_axis_knife_bit_z"
              id="ptp_setting_zSafeHeight_input"
              @dragover="handleDragOver"
              @input="
                verifyInput(
                  newFormData.main_axis_knife_bit_z,
                  newFormData,
                  ['main_axis_knife_bit_z'],
                  '5-2',
                  true,
                  '',
                  true
                )
              "
            ></a-input>
          </div>
          <div>
            <span class="small-title">{{ $t('PTP.sideDrillZ') }}：</span>
            <a-input
              v-model="newFormData.side_drill_bit_z"
              id="ptp_setting_sideDrillZ_input"
              @dragover="handleDragOver"
              @input="
                verifyInput(
                  newFormData.side_drill_bit_z,
                  newFormData,
                  ['side_drill_bit_z'],
                  '5-2',
                  true,
                  '',
                  true
                )
              "
            ></a-input>
          </div>
          <div>
            <span class="small-title">{{ $t('PTP.sideDrillXY') }}：</span>
            <a-input
              v-model="newFormData.side_drill_bit_xy"
              id="ptp_setting_sideDrillXy_input"
              @dragover="handleDragOver"
              @input="
                verifyInput(
                  newFormData.side_drill_bit_xy,
                  newFormData,
                  ['side_drill_bit_xy'],
                  '5-2',
                  true,
                  '',
                  true
                )
              "
            ></a-input>
          </div>
        </div>
        <div class="assit-function">
          <span class="head-title">{{ $t('PTP.auxiliaryFunction') }}</span>
          <div>
            <span class="small-title">{{ $t('PTP.auxiliaryFunction') }}：</span>
            <a-checkbox
              v-model="newFormData.xhDedicatedInfo"
              id="ptp_setting_xhDedicatedInfo_checkbox"
              ><span id="ptp_setting_xhDedicatedInfo_checkbox_label">{{
                $t('PTP.xhDedicatedInfo')
              }}</span></a-checkbox
            >
            (<a-checkbox
              :disabled="!newFormData.xhDedicatedInfo"
              v-model="newFormData.xhE13Value"
              id="ptp_setting_xhE13Value_checkbox"
              ><span id="ptp_setting_xhE13Value_checkbox_label"
                >{{ $t('PTP.xhCheckBox') }}#E13=0</span
              ></a-checkbox
            >)
          </div>
          <div>
            <span class="small-title">主轴刀加工设置：</span>
            <a-checkbox
              v-model="newFormData.zzdCutLast"
              id="ptp_setting_zzdCutLast_radiocheckbox"
            ></a-checkbox>
            <span class="ml8">主轴刀在最后加工</span>
          </div>
        </div>
        <div class="assit-function">
          <span class="head-title">{{ $t('PTP.outputSettings') }}</span>
          <div>
            <span class="small-title">{{ $t('PTP.outputSettings') }}</span>
            <a-checkbox
              v-model="newFormData.size_annotate"
              id="ptp_setting_exportSetting_checkbox"
              ><span id="ptp_setting_exportSetting_checkbox_label">{{
                $t('PTP.partExegesis')
              }}</span></a-checkbox
            >
          </div>
        </div>
      </div>
      <div class="mpr-setting" v-else>
        <span class="knife-num head-title">{{ $t('PTP.knifeNo') }}</span>
        <div class="knife-setting-content">
          <div>
            <span class="small-title">{{ $t('PTP.specailKnifeNo') }}</span>
            <div class="knife-input">
              <a-input
                :placeholder="$t('PTP.fileNamePlaceholder')"
                v-model="newFormData.specialShapeKnives"
                id="ptp_setting_specialShapeKnives_input"
              ></a-input>
            </div>
          </div>
          <div>
            <span class="small-title">{{ $t('PTP.slotKnifeNo') }}</span>
            <div class="knife-input">
              <a-input
                :placeholder="$t('PTP.fileNamePlaceholder')"
                v-model="newFormData.slotsKnives"
                id="ptp_setting_slotsKnives_input"
              ></a-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <m-lock-modal
      :visible.sync="isShowVisible"
      :status.sync="lockStatus"
      :tempStatus.sync="tempStatus"
      :verifyPassword="verifyPassword"
      :lineName="$route.query.line"
      @getLockPassword="handleGetLockPassword"
      @handleChangePasswordState="handleChangePasswordState"
    ></m-lock-modal>

    <!-- 修改记录弹窗 -->
    <m-change-record-modal
      :visible.sync="showRecordModal"
      operate_equipment="ptpHoleDrill"
      @success="handleSuccess"
      ref="recordModalRef"
    />
  </div>
</template>

<script>
import { getLockStatus } from '@/apis/productionLineLock'
import breadcrumb from '@/components/topBreadCrumb.vue'
import store from '@/store'
import { translate } from '@/util/commonFun'
import { genUniId } from '@/util/commonFuncs'
import {
  addIdForItems,
  genFinalRecord,
  genFinalRecordPromise,
  verifyInputNumber,
} from '@/util/commonFuncs'
import { operateRecordTableType, webId } from '@/util/configure'
import {
  bluePtpFormTextObj,
  bluePtpFormValueObj,
} from '@/util/operateRecordConfigure/bluenPtpConfigure'
import {
  regReplaceBluenPtpDealNumber,
  regReplaceCuttingParamsDiameter,
} from '@/util/regReplace'
import { saveDataJsonToOssForRecord } from '@/util/saveFile'
import MChangeRecordModal from '@/views/equipment/component/m-change-record-modal.vue'
import MLockModal from '@/views/equipment/component/m-lock-modal.vue'
import { mapState } from 'vuex'

import MRefuseHistoryModal from '../taskList/components/m-refuse-history-modal.vue'

export default {
  components: {
    breadcrumb,
    MLockModal,
    MChangeRecordModal,
  },
  data() {
    return {
      // 记录刀库列
      knivesColumns: [
        { label: 'cuttingDock.knives.number', props: 'id' },
        { label: 'cuttingDock.knives.type', props: 'type' },
        {
          label: 'cuttingDock.knives.diameter',
          props: 'diameter',
        },
        { label: 'cuttingDock.knives.offsetX', props: 'offsetX' },
        { label: 'cuttingDock.knives.offsetY', props: 'offsetY' },
        { label: 'cuttingDock.knives.offsetZ', props: 'offsetZ' },
        { label: 'cuttingDock.knives.offsetNo', props: 'offsetNo' },
        {
          label: 'cuttingDock.knives.drill',
          props: 'drillVelocity',
          unit: '[F]mm/min',
        },
        {
          label: 'cuttingDock.knives.cut',
          props: 'cutVelocity',
          unit: '[F]mm/min',
        },
        {
          label: 'cuttingDock.knives.speed',
          props: 'rotationlSpeed',
          unit: '[S]r/min',
        },
      ],
      // 记录老板ptp配置
      formData: {
        // 记录刀库
        knives: [],
        // 程序结束代码
        beginCode: '',
        // 程序结束代码
        endCode: '',
        // 主轴刀换刀开始代码
        zzdKnifeBeginCode: '',
        // 主轴刀换刀结束代码
        zzdKnifeEndCode: '',
        // 垂直钻开始代码
        czzBeginCode: '',
        // 垂直钻结束代码
        czzEndCode: '',
        // 垂直钻换刀开始代码
        czzKnifeBeginCode: '',
        // 垂直钻换刀结束代码
        czzKnifeEndCode: '',
        // 侧钻开始代码
        czBeginCode: '',
        // 侧钻结束代码
        czEndCode: '',
        // 侧钻换刀开始代码
        czKnifeBeginCode: '',
        // 侧钻换刀结束代码
        czKnifeEndCode: '',
        // 暂停代码
        pauseCode: '',
        // 横向坐标
        xPointSymbol: 'normal',
        // 纵向坐标
        yPointSymbol: 'normal',
        // 升降坐标
        zPointSymbol: 'normal',
      },
      // 记录班鲁新增功能配置
      newFormData: {
        // 输出文件格式 默认为default, 可选值: mpr
        ptpFileFormat: 'default',
        // 加工异形刀具编号, 没有默认值
        specialShapeKnives: '',
        // 开槽道具编号, 没有默认值
        slotsKnives: '',
        // 记录Z轴安全高度
        main_axis_knife_bit_z: '',
        side_drill_bit_z: '',
        side_drill_bit_xy: '',
        // 记录是否选中星辉专用信息
        xhDedicatedInfo: false,
        zzdCutLast: false,
        // x轴镜像
        xDatumSwap: false,
        // y轴镜像
        yDatumSwap: false,
        size_annotate: true,
        xyReverse: false,
        // 文件格式设置
        fileFormatSetting: {
          platform: 'windows',
          fileEncoding: 'utf-8',
          fileSuffix: 'false',
        },
        xhE13Value: false,
      },
      // 标签池
      tagList: ['成品宽度', '成品长度', '主轴刀编号', '垂直钻编号', '侧钻编号'],
      // 记录token
      token: '',
      // 记录当前点击的行
      currentRow: {},
      // 选择刀具
      knives: [
        { name: 'PTP.spindleCutter', value: '主轴刀', label: '' },
        { name: 'PTP.verticalDrill', value: '垂直钻', label: '' },
        { name: 'PTP.sidetrack', value: '侧钻↑', label: '', unit: '↑' },
        { name: 'PTP.sidetrack', value: '侧钻↓', label: '', unit: '↓' },
        { name: 'PTP.sidetrack', value: '侧钻←', label: '', unit: '←' },
        { name: 'PTP.sidetrack', value: '侧钻→', label: '', unit: '→' },
        { name: 'PTP.slotDrill', value: '拉直器刀', label: '' },
        { name: '', value: '', label: '' },
      ],
      // 记录单元格索引
      rowIndex: -1,
      columnIndex: -1,
      zSafeHeight: '',
      // 记录是否从天工来
      showTiangong: false,
      // 记录是否可以进行保存
      ableSaveSetting: false,
      // 原始数据
      oriFormData: {},
      lockStatus: 1, // 锁状态
      verifyPassword: true,
      isShowVisible: false,
      tempStatus: 1, //默认状态,
      showRecordModal: false,
      isShowVisibleChangeRecordModal: false,
    }
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapState('equipEnvironment', ['previewProductLine', 'previewInfo']),
  },
  methods: {
    translateLang(key) {
      return translate(key)
    },
    async handleGetLockPassword() {
      await this.ptpdataTransmit()
    },
    handleChangePasswordState(state) {
      this.verifyPassword = state
    },
    // 更改行样式
    changeStyle({ row, rowIndex }) {
      if (this.currentRow.index == rowIndex) {
        return {
          height: '54px',
          'border-top': '1px solid #EEEEEE',
          'font-size': '14px',
          color: '#000000',
          'font-weight': '400',
          background: 'rgba(24, 168, 199, 0.2)',
        }
      }
      return {
        height: '54px',
        'border-top': '1px solid #EEEEEE',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '400',
      }
    },
    // 更改单元格样式
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'id') {
        return 'padding-left: 16px'
      }
    },
    // 更改表头单元格样式
    headerStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'id') {
        return 'padding-left: 16px; box-sizing: border-box;background: #fafafa'
      }
      return 'background: #fafafa'
    },
    // 更改表头行样式
    headerRowStyle({ row, rowIndex }) {
      return {
        height: '54px',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '500',
        'border-bottom': 'none',
      }
    },
    //把每一行的索引加到行数据中
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    //把每一列的索引加到列数据中
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    // 选中刀具
    choseKnife(row, column, event) {
      this.currentRow = row
    },
    // 回到设备对接页面
    goBackPage() {
      this.nowPath = ''
      let path = ''
      if (this.showTiangong) {
        path = '/equipment?from=tiangong'
      } else {
        path = '/equipment'
      }
      if (this.previewInfo.isPreview) {
        path = `${path}-preview`
      }

      this.$router.push(path)
    },
    // 处理数据
    dealNumber() {
      if (this.newFormData.zSafeHeight == 0) {
        this.newFormData.zSafeHeight = ''
      } else {
        // 设置只能输入数字, 且只能保留两位小数
        this.newFormData.zSafeHeight = regReplaceBluenPtpDealNumber(
          this.newFormData.zSafeHeight
        )
      }
    },
    // 验证输入框输入, 新增需求长边限制允许输入为空，兼容其他使用地方，传入默认参数
    verifyInput(
      val,
      obj,
      keys,
      rule,
      isMinus = false,
      min = 0,
      isEmpty = false
    ) {
      verifyInputNumber(val, obj, keys, rule, isMinus, min, isEmpty)
    },
    // 生成比较用的表单数据
    genCompareFormData(current, ori) {
      let compareMsg = [
        {
          title: '输出文件格式',
          keys: ['ptpFileFormat'],
        },
        {
          title: '小板坐标',
          keys: ['xPointSymbol', 'yPointSymbol', 'zPointSymbol'],
        },
        {
          title: '板件镜像',
          keys: ['xDatumSwap', 'yDatumSwap'],
        },
        {
          title: '程序',
          keys: ['beginCode', 'endCode'],
        },
        {
          title: '主轴刀',
          keys: ['zzdKnifeBeginCode', 'zzdKnifeEndCode'],
        },
        {
          title: '垂直钻',
          keys: [
            'czzBeginCode',
            'czzEndCode',
            'czzKnifeBeginCode',
            'czzKnifeEndCode',
          ],
        },
        {
          title: '侧钻',
          keys: [
            'czBeginCode',
            'czEndCode',
            'czKnifeBeginCode',
            'czKnifeEndCode',
          ],
        },
        {
          title: '暂停',
          keys: ['pauseCode'],
        },
        {
          title: '主轴刀和垂直钻Z轴安全高度',
          keys: ['main_axis_knife_bit_z'],
        },
        {
          title: '侧钻Z轴安全高度',
          keys: ['side_drill_bit_z'],
        },
        {
          title: '侧钻X/Y轴安全距离',
          keys: ['side_drill_bit_xy'],
        },
        {
          title: '辅助功能',
          keys: ['xhDedicatedInfo', 'xhE13Value', 'zzdCutLast'],
        },
        {
          title: '刀具编号',
          keys: ['specialShapeKnives', 'slotsKnives'],
        },
        {
          title: '输出设置',
          keys: ['size_annotate'],
        },
        {
          title: 'xy轴互换',
          keys: ['xyReverse'],
        },
        {
          title: '文件格式设置',
          keys: ['fileFormatSetting'],
        },
      ]
      return {
        type: 'form',
        current,
        ori,
        compareMsg,
        formTextObj: bluePtpFormTextObj,
        formValueObj: bluePtpFormValueObj,
      }
    },
    // 生成最终的操作记录
    async genFinalRecordFn(current, ori, saveData, callback = null) {
      let recordData = {
        key: 'PTP', // 操作记录最外层的名字，比如：雕刻机
        operateArr: [
          // 应对多个操作页面时，比如雕刻机的操作页面
          {
            key: '',
            dataArr: [
              // 分为表单和table两种
              {
                type: 'table',
                list: current.knives,
                oriList: ori.knives,
                keys: this.knivesColumns.map((item) => {
                  return item.props
                }),
                tableType: operateRecordTableType.PTP_KNIFE_STORE_TABLE,
                key: '刀库',
              },
              this.genCompareFormData(current, ori),
            ],
          },
        ],
      }
      const res = await genFinalRecordPromise(recordData, 'equipment_docking')
      if (res) {
        const { record, finalId, action_type } = res
        saveDataJsonToOssForRecord(
          record,
          action_type,
          {
            id: finalId,
            operate_equipment: 'ptpHoleDrill',
            save_data: saveData,
            setting_id: this.$route.query.setting_id,
            operatorPhone: this.previewInfo.operatorPhone,
          },
          () => {
            if (this.$route.query.setting_id && !this.previewInfo.isApply) {
              this.getPtpSetting()
            }
            callback && callback()
          }
        )
      }
    },
    // 保存配置
    ptpdataTransmit() {
      // 判断锁定状态，打开弹窗
      // 根据获取的状态 锁定状态
      this.lockStatus = this.tempStatus
      if (this.showTiangong) {
        this.$message({
          message: this.$t('cuttingDock.permissionErr'),
          type: 'info',
          duration: 1500,
          offset: 60,
        })
        return
      }
      // 将安全距离相关值转换成number类型再进行保存
      this.newFormData.main_axis_knife_bit_z =
        +this.newFormData.main_axis_knife_bit_z
      this.newFormData.side_drill_bit_z = +this.newFormData.side_drill_bit_z
      this.newFormData.side_drill_bit_xy = +this.newFormData.side_drill_bit_xy
      let data = JSON.stringify({
        ...store.state.saveInfo,
        platform: 'bluen',
        setting_id: this.$route.query.setting_id,
        ptp_setting: this.formData,
        bluen_ptp_setting: this.newFormData,
      })
      this.ableSaveSetting = false
      this.$store.commit('setCurrentProductLineName', this.$route.query.line)
      this.$token('/save_ptp_setting', data, (res) => {
        this.ableSaveSetting = true
        if (res.status == 0) {
          this.$message({
            type: 'error',
            message: res?.msg || this.$t('lineManager.permissionTip'),
            duration: 1500,
            offset: 70,
          })
          window.sessionStorage.removeItem('token')
        } else if (res.status == -1) {
          if (store.state.saveInfo.phone) {
            this.$message({
              message: res.msg,
              type: 'error',
              duration: 1500,
              offset: 70,
            })
          }
          this.showSaveModal()
        } else {
          this.isShowVisible = false
          try {
            this.genFinalRecordFn(
              {
                ...this.formData,
                ...this.newFormData,
              },
              this.oriFormData,
              data
            )
          } catch (error) {
            console.error('error', error)
          }
          if (res.data.phone && res.data.request_ip) {
            let recordData = {
              key: '保存设置',
              operateArr: [
                {
                  key: '生产线设置',
                  dataArr: [
                    {
                      type: 'form',
                      current: {
                        phone: res.data.phone,
                        time: new Date().toLocaleString(),
                        id: store.state.userInfo.id,
                        ip: res.data.request_ip,
                      },
                      ori: {
                        phone: '',
                        time: '',
                        id: '',
                        ip: '',
                      },
                      compareMsg: [
                        {
                          title: '',
                          keys: ['phone', 'time', 'id', 'ip'],
                        },
                      ],
                      formTextObj: {
                        phone: '手机号',
                        time: '保存时间',
                        id: '用户id',
                        ip: '物理地址',
                      },
                      formValueObj: {},
                    },
                  ],
                },
              ],
            }
            genFinalRecord(recordData, 'equipment_docking', '', () => {})
          }
          this.$message({
            type: 'success',
            message: this.$t('common.saveSuccess'),
            duration: 1500,
            offset: 70,
          })
        }
        store.commit('setSaveInfo', {})
      })
    },
    showSaveModal() {
      this.lockStatus = this.tempStatus
      this.verifyPassword = true
      this.isShowVisible = true
    },
    // 获取配置
    getPtpSetting() {
      this.$token(
        '/load_ptp_setting',
        {
          setting_id: this.$route.query.setting_id,
        },
        (res) => {
          if (res.status == 1) {
            this.dealPtpSetting(res)
            this.oriFormData = JSON.parse(
              JSON.stringify({
                ...this.formData,
                ...this.newFormData,
              })
            )
            // 后台应用生产线时进入
            if (this.previewProductLine?.ptp) {
              this.dealPtpSetting({
                data: this.previewProductLine?.ptp,
              })
            }
          } else {
            this.$message({
              type: 'info',
              message: this.$t('docker56Drill.permissionErr'),
              duration: 1500,
              offset: 70,
            })
            window.sessionStorage.removeItem('token')
            window.sessionStorage.removeItem('bluser')
          }
        }
      )
    },
    cellClick(row, column, cell, event) {
      this.rowIndex = row.index
      this.columnIndex = column.index
      if (column.property != 'type') {
        this.$nextTick(() => {
          this.$refs['editInput'][0].focus()
        })
      }
    },
    // 修改刀具
    modifyKnife() {
      this.rowIndex = -1
      this.columnIndex = -1
    },
    // 修改其他值
    inputBlur(row) {
      this.rowIndex = -1
      this.columnIndex = -1
      // 失去焦点判断刀直径是否为0
      if (row['diameter'] === 0) {
        row['diameter'] = ''
      }
      if (row['diameter']) {
        row['diameter'] = Number(row['diameter'])
      }
    },
    // 添加刀具
    addNewKnife() {
      this.formData.knives.push({
        id: '',
        type: '',
        diameter: '',
        offsetX: '',
        offsetY: '',
        offsetZ: '',
        offsetNo: '',
        drillVelocity: '',
        cutVelocity: '',
        rotationlSpeed: '',
        [webId]: genUniId(),
      })
      this.rowIndex = this.formData.knives.length - 1
      this.columnIndex = 0
      this.$nextTick(() => {
        this.$refs['editInput'][0].focus()
      })
    },
    // 删除刀具
    deleteKnife() {
      this.formData.knives.splice(
        this.formData.knives.findIndex((v) => v.index == this.currentRow.index),
        1
      )
    },
    startDrag(event, item) {
      this.dragWord = `{${item}}`
      this.$nextTick(() => {
        event.dataTransfer.setData('Text', event.target.classList[0])
      })
    },
    handleDragOver(event, item) {
      this.formData[item] += this.dragWord
      this.$nextTick(() => {
        event.preventDefault()
      })
    },
    // 复制标签
    copyTag(index) {
      var clipboard = new this.$copy(`.tag-item${index}`)
      clipboard.on('success', (e) => {
        this.$message({
          type: 'success',
          message: '复制成功!',
          offset: 70,
          duration: 1000,
        })
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        this.$message({
          type: 'success',
          message: '复制失败, 请手动选中复制!',
          offset: 70,
          duration: 1000,
        })
        clipboard.destroy()
      })
    },
    handleGetLockStatus() {
      //TODO 进入页面，获取生产线设置锁的状态
      getLockStatus().then((res) => {
        // 拿到的锁状态赋值，当前是锁定还是解锁状态
        this.lockStatus = res?.data?.is_lock || 0
        this.tempStatus = res?.data?.is_lock || 0
      })
    },
    // 输入校验
    handleInputCheck(row, prop) {
      // 刀径只能输入数字且不能为负数和0
      if (prop === 'diameter') {
        row[prop] = regReplaceCuttingParamsDiameter(row[prop])
      } else if (prop === 'fileSuffix') {
        this.newFormData.fileFormatSetting.fileSuffix = row.replace(
          /[^\w.]/g,
          ''
        )
      }
    },
    // 打开修改记录弹窗
    handleOpenChangeRecord() {
      this.showRecordModal = true
    },
    // 还原参数后的回调
    handleSuccess(newData) {
      const data = JSON.parse(newData.save_data)
      const { ptp_setting: formData, bluen_ptp_setting: newFormData } = data
      this.genFinalRecordFn(
        {
          ...formData,
          ...newFormData,
        },
        this.oriFormData,
        newData.save_data,
        () => {
          this.$refs['recordModalRef']?.getData({ isReset: true })
        }
      )
    },

    // 处理ptp设置数据
    dealPtpSetting(res) {
      this.ableSaveSetting = true
      if (res.data.ptp_setting != null) {
        // 为每个刀具添加webId
        addIdForItems(res.data.ptp_setting.knives)
        this.formData = res.data.ptp_setting
        // 如果没有保存小板坐标和暂停代码, 则设置默认值
        this.formData.xPointSymbol = res.data.ptp_setting.xPointSymbol
          ? res.data.ptp_setting.xPointSymbol
          : 'normal'
        this.formData.yPointSymbol = res.data.ptp_setting.yPointSymbol
          ? res.data.ptp_setting.yPointSymbol
          : 'normal'
        this.formData.zPointSymbol = res.data.ptp_setting.zPointSymbol
          ? res.data.ptp_setting.zPointSymbol
          : 'normal'
        this.formData.pauseCode = res.data.ptp_setting.pauseCode
          ? res.data.ptp_setting.pauseCode
          : ''
      }
      // 老数据，接口没有fileFormat字段，直接赋值覆盖对象会导致表单项中缺少fileFormat字段
      Object.keys(res.data.bluen_ptp_setting).forEach((key) => {
        this.newFormData[key] = res.data.bluen_ptp_setting[key]
      })
    },
  },
  mounted() {
    this.handleGetLockStatus()
    if (this.$route.query.from) {
      if (this.$route.query.from == 'tiangong') {
        this.showTiangong = true
      }
      return
    }
    this.showTiangong = false
    if (window.sessionStorage.getItem('token')) {
      this.token = window.sessionStorage.getItem('token')
      this.getPtpSetting()
    } else {
      this.$message({
        type: 'info',
        message: this.$t('dock56Drill.permissionErr'),
        duration: 1500,
        offset: 70,
      })
    }
  },
}
</script>

<style scoped lang="less">
// .ptp-setting-full {
//   height: calc(100vh - 60px);
// }
.ptp-setting {
  .head-title {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 18px;
  }

  .small-title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
  }
  .fileSuffix-input {
    width: 100px;
  }
  .fileSuffix-span {
    color: rgba(0, 0, 0, 0.25);
  }
  .wrap {
    margin: 16px;
    .file-format {
      width: 100%;
      background: #fff;
      .file-format-title {
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        padding: 24px 24px 16px 24px;
        border-bottom: 1px solid #e9e9e9;
      }

      .file-format-radio {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 24px;
      }
    }
    .default-setting {
      .bluen-ptp {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 24px;
        padding-bottom: 24px;
        background: #fff;

        .small-plank {
          .small-plank-title {
            display: inline-block;
            box-sizing: border-box;
            width: 100%;
            padding: 16px 24px 16px 24px;
            border-bottom: 1px solid #e9e9e9;
          }
          .small-plank-content {
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            padding: 24px;
            & > div {
              width: 100%;
            }
          }
        }
        .knife-table {
          .knife-table-title {
            display: inline-block;
            box-sizing: border-box;
            width: 100%;
            padding: 16px 24px;
            border-bottom: 1px solid #e9e9e9;
          }
          .table-box {
            box-sizing: border-box;
            padding: 24px;
            /deep/.el-table {
              &::before {
                background: transparent;
              }
              .has-gutter tr {
                background-color: #fafafa;
              }
              .hidden-columns {
                display: none;
              }
              .el-table__body-wrapper {
                max-height: 240px;
                overflow: scroll;
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
          }
          .knife-operation {
            box-sizing: border-box;
            margin-top: 8px;
            padding: 0 24px;
          }
        }
      }
      .code-box {
        width: 100%;
        margin: 0 auto;
        background: #fff;
        .tag-lake {
          box-sizing: border-box;
          width: 100%;
          padding: 32px 24px 32px 24px;
          .tag-lake-title {
            display: flex;
            display: inline-block;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            padding: 11px 24px;
            background: #e9e9e9;
            span:first-child {
              margin-right: 8px;
              color: #000;
              font-weight: 600;
              font-size: 16px;
            }
          }
          .tag-list {
            box-sizing: border-box;
            width: 100%;
            padding: 24px 24px 17px 24px;
            border: 1px solid #e9e9e9;
            span {
              display: inline-block;
              width: 102px;
              height: 32px;
              margin-right: 8px;
              color: #5a5a5a;
              line-height: 32px;
              text-align: center;
              background: #eaf4f6;
              cursor: pointer;
              user-select: none;
            }
          }
        }
        .code-content {
          .code-content-title {
            display: block;
            box-sizing: border-box;
            width: 100%;
            padding: 8px 24px 16px 24px;
            border-bottom: 1px solid #e9e9e9;
          }
          .text-area {
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            padding: 24px 24px 0 24px;
            div {
              margin-bottom: 24px;
            }
            div:nth-child(2n + 1) {
              margin-right: 128px;
            }
            span {
              color: rgba(0, 0, 0, 0.8);
              font-weight: 600;
            }
            textarea {
              display: block;
              width: 466px;
              height: 113px;
              margin-top: 8px;
              border: 1px solid #dddddd;
              border-radius: 4px;
              resize: none;
            }
          }
        }
      }
      .safe-height {
        background: #fff;
        div {
          box-sizing: border-box;
          padding: 24px;
          .ant-input {
            width: 283px;
          }
        }
      }
      .assit-function {
        background: #fff;
        div {
          box-sizing: border-box;
          padding: 24px;
        }
      }
    }
    .mpr-setting {
      background: #fff;
      .knife-num {
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        padding: 8px 24px 24px 24px;
        border-bottom: 1px solid #e9e9e9;
      }
      .knife-setting-content {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 24px 29px;
        & > div {
          display: flex;
          align-items: center;
          margin-right: 80px;
          span {
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>
